import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import Layout from '../components/Layout'

const PlansPage = ({ data, location }) => {
  const page = data.prismicPlans.data
  return (
    <Layout>
      <SEO
        title={page.meta_title}
        description={page.meta_description}
        location={location}
      />
      <div className="pb-5 pt-32 lg:pt-36 lg:pb-40">
        <div className="relative">
          <div className="hidden lg:block absolute pin-t pin-l w-1/2 h-full pl-1/48">
            <div className="sticker type-lg lg:type-3xl text-white">
              <div dangerouslySetInnerHTML={{ __html: page.title }} />
            </div>
          </div>
          <div className="grid">
            <div className="grid__half--primary lg:hidden">
              <div className="w-3/4">
                <h1 className="type-lg lg:type-3xl text-white">{page.title}</h1>
              </div>
            </div>
            <div className="grid__half--secondary rte pt-4">
              <div
                className="lg:type-lg lg:mb-16"
                dangerouslySetInnerHTML={{ __html: page.lead.html }}
              />
              <div dangerouslySetInnerHTML={{ __html: page.body.html }} />
            </div>
          </div>
        </div>
      </div>
      {page.locations.map((item, i) => {
        return (
          <div key={i}>
            <div className="grid mb-16">
              <div className="grid__full">
                <div className="relative flex justify-center items-center">
                  <img
                    src={item.location_image.url}
                    alt={item.location_image.alt}
                  />
                  <div className="absolute w-2/3 flex justify-center">
                    <img
                      src={item.location_name.url}
                      alt={item.location_name.alt}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid mb-12 lg:mb-30">
              <div className="hidden lg:block grid__half--primary">
                <img src={item.location_map.url} alt={item.location_map.alt} />
              </div>

              <div className="grid__half--secondary">
                <h2 className="type-xl lg:type-3xl text-white mb-4 lg:mb-20">
                  {item.location_title}
                </h2>
                <div
                  className="rte"
                  dangerouslySetInnerHTML={{ __html: item.location_body.html }}
                />
              </div>
            </div>
            {i < page.locations.length - 1 && (
              <div className="grid mb-8">
                <div className="grid__full border-b border-grey-darkest" />
              </div>
            )}
          </div>
        )
      })}
    </Layout>
  )
}

PlansPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const pagePlansQuery = graphql`
  query {
    prismicPlans {
      data {
        title
        lead {
          html
        }
        body {
          html
          text
        }
        locations {
          location_title
          location_image {
            alt
            url
          }
          location_name {
            alt
            url
          }
          location_body {
            html
            text
          }
          location_map {
            alt
            url
          }
        }
        meta_title
        meta_description
      }
    }
  }
`

export default PlansPage
